import React, { useEffect, useState } from 'react'
import Nav from '../components/Nav'
import logo from '../assets/imgs/logo.png';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const Home = () => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);
    const [showHrDialog, setShowHrDialog] = useState(false);
    const [userData, setUserData] = useState({});

    const [punchInBtn, setPunchInButton] = useState(true) //true mean `enable`  |  true mean `disable`
    const [punchOutBtn, setPunchOutButton] = useState(true) //true mean `enable`  |  false mean `disable`

    const [showPunchIn, setShowPunchIn] = useState(true)// true mean display `block` | false mean `none`
    const [showPunchOut, setShowPunchOUt] = useState(true);
    const [hrRequestBtn, setHrRequestBtn] = useState(false);

    const [workReport, setWorkReport] = useState('');
    const [attendance, setAttendance] = useState({ punchIn: '', punchOut: '' });

    const checkAttendance = async () => {
        const req = await fetch('/api/employee/attendance/check', {
            method: "POST",
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(userData)
        });

        const res = await req.json();

        if (req.status === 200 && res.data) {
            const time = Number(res.time);
            console.log(res.data)

            // (res.data === 'null' && (time > 9 && time < 12))
            if (res.data === 'null') {
                setPunchInButton(true);
                setPunchOutButton(false);
            }
            // if (res.data === 'null' && time > 12) {
            //     // setHrRequestBtn(true)
            //     setShowPunchIn(false)
            //     setShowPunchOUt(false);
            // }
            if (res.data.inTime && !res.data.outTime) {
                setPunchInButton(false);
                setPunchOutButton(true);
            }
            if (res.data.inTime && res.data.outTime) {
                setPunchInButton(false);
                setPunchOutButton(false);

            } 
            // else {
            //     setPunchInButton(false);
            //     setPunchOutButton(false);
            //     console.log('run else')
            // }
        }

    }

    useEffect(() => {
        checkAttendance();
    }, [userData])


    const punchIn = async () => {
        try {
            const req = await fetch("/api/employee/punchin", {
                method: 'POST',
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(userData)
            });
            const res = await req.json();
            if (req.status === 200 && res.create === true) {
                setPunchInButton(false);
                setPunchOutButton(true);
                toast.success("Attendance successfully", {
                    autoClose: 2000,
                    position: 'top-center',
                    closeOnClick: true
                })
            }
        } catch {
            alert("Something went wrong");
        }
    }



    const punchOut = () => {
        setShowDialog(true);
    }

    // Send work report and `punchOut`;
    const sendWorkReport = async () => {
        const sendData = { ...userData, report: workReport };

        try {
            if (sendData.report !== "") {
                const out_req = await fetch('/api/employee/punchout', {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json"
                    },
                    body: JSON.stringify(sendData)
                })
                const out_res = await out_req.json();
                console.log(out_res)

                if (out_req.status === 200 && out_res.create === true) {
                    setShowDialog(false);
                    setPunchOutButton(false);

                    toast.success("Thank You!!", {
                        autoClose: 2000,
                        position: 'top-center',
                        closeOnClick: true
                    })
                } else {
                    alert(out_res.error)
                }
            } else {
                alert('Write your work report')
            }

        } catch {
            alert('Someting went wrong');
        }

    }

    const checkAuth = async () => {
        const check = await fetch("/api/employee/check");
        const check_res = await check.json();

        if (check.status === 401 && check_res.login === false) {
            navigate("/login")
        } else {
            setUserData({ ...check_res.id });
            navigate("/")

        }

    }
    useEffect(() => {
        checkAuth()
    }, [])


    return (
        <>
            <Nav />
            <ToastContainer />

            <div className="dialog" style={showDialog === true ? { "display": "grid" } : { "display": "none" }}>
                <div className="dialog_box relative">
                    <div className='absolute right-[-10px] top-[-10px] w-[25px] h-[25px] rounded-full
                     bg-red-500 text-white grid place-items-center cursor-pointer' onClick={() => setShowDialog(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    <h1 className='py-2 font-bold'>Work Report</h1>
                    <textarea name="message" rows="5"
                        className='mt-2 outline-none border rounded-sm focus:border-blue-500 p-2 text-sm'
                        placeholder='write work report here...'
                        value={workReport}
                        onChange={(e) => setWorkReport(e.target.value)}
                    ></textarea>
                    <button
                        className='mt-5 mb-2 bg-blue-600 rounded-sm p-1 text-white'
                        onClick={sendWorkReport}>
                        Send
                    </button>
                </div>
            </div>

            {/*  HR Message dialog*/}
            <div className="dialog" id="hrDialog"
                style={{ "display": showHrDialog ? "grid" : "none" }}
            >
                <div className="dialog_box relative">
                    <div className='absolute right-[-10px] top-[-10px] w-[25px] h-[25px] rounded-full
                     bg-red-500 text-white grid place-items-center cursor-pointer' onClick={() => setShowHrDialog(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    <h1 className='py-2 font-bold'>HR request</h1>
                    <textarea name="message" rows="5"
                        className='mt-2 outline-none border rounded-sm focus:border-blue-500 p-2 text-sm'
                        placeholder='write request message here...'
                    ></textarea>
                    <button
                        className='mt-5 mb-2 bg-blue-600 rounded-sm p-1 text-white'
                    >
                        Send
                    </button>
                </div>
            </div>



            <main id='main' className='w-[calc(100%-170px)] min-h-[100vh] float-right bg-[#F1F2F7] px-3 grid place-items-center'>

                <div className='w-[450px] flex-col h-auto p-3 relative text-white rounded-lg flex justify-center'>
                    <div className='flex justify-center absolute top-[-60px] left-[38%]'>
                        <img src={logo} alt="user_logo" className='h-[100px] border rounded-full' />
                    </div>
                    <div className='bg-white flex items-center justify-center gap-5 h-[200px] shadow-md'>

                        <button
                            onClick={() => setShowHrDialog(true)}
                            title='Under work'
                            style={hrRequestBtn ? { "display": "block" } : { 'display': "none" }}
                            className='bg-green-400 p-2 rounded-sm'>
                            HR request
                        </button>

                        <button
                            disabled={punchInBtn === true ? false : true}
                            className='bg-blue-600 rounded-sm p-2 hover:bg-blue-700 h-[40px] text-sm w-[100px]'
                            onClick={punchIn}
                            style={{
                                "backgroundColor": !punchInBtn ? "gray" : "rgb(37 99 235)",
                                "display": showPunchIn ? "block" : "none"
                            }}
                        >
                            Punch In
                        </button>

                        <button
                            disabled={punchOutBtn === true ? false : true}
                            className='bg-orange-400 rounded-sm p-2 hover:bg-orange-500 h-[40px] text-sm w-[100px]'
                            style={{
                                "backgroundColor": !punchOutBtn ? "gray" : "rgb(251 146 60)",
                                "display": showPunchOut ? "block" : "none"
                            }}
                            onClick={punchOut}
                        >
                            Punch Out
                        </button>
                    </div>
                </div>
            </main>
        </>
    )

}

export default Home
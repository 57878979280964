import React, { useEffect, useState } from 'react';
import Nav from '../components/Nav';
import { useNavigate } from 'react-router-dom';

const Changepassword = () => {
    const [password, setPassword] = useState(""); //  chagne password value store here
    const [userData, setUserData] = useState({}); // user or employee data store here
    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false)
    const navigate = useNavigate();


    const checkAuth = async () => {
        const check = await fetch("/api/employee/check");
        const check_res = await check.json();

        if (check.status === 401 && check_res.login === false) {
            navigate("/login")
        } else {
            navigate("/changepassword")
            setUserData({ ...check_res.id });

        }

    }
    useEffect(() => {
        checkAuth()
    }, [])


    const changePassFunc = async () => {
        const data = { password: password, email: userData.email }

        if (data.password && data.email) {
            try {
                const req = await fetch("/api/employee/password/change", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                })
                const res = await req.json();
                if (req.status === 200 && res.change) {
                    setSuccessMsg(true);
                    setPassword(""); // Clear the password
                } else {
                    alert(req.error);
                }
            } catch (e) {
                alert("Something went wrong");
            }
        } else {
            setErrorMsg(true)
        }

    }
    return (
        <>
            <Nav />
            <main id='main' className='w-[calc(100%-170px)] min-h-[100vh] float-right bg-[#F1F2F7] px-3 grid place-items-center'>

                <div
                    className='bg-white relative rounded-md w-[450px] h-[200px] flex  flex-col gap-5 px-5'>
                    <h1 className='mt-3 font-medium text-blue-500 text-sm text-center'>Change Password</h1>

                    <div className='w-full flex flex-col mt-3'>
                        <div
                            className='text-[12px] text-green-500 mb-2 text-center flex gap-2 items-center justify-center'
                            style={{ "display": successMsg ? "flex" : "none" }}
                        >
                            <span>Password change success</span>
                            <i className="fa-solid fa-check bg-green-500 text-white w-[15px] h-[15px] grid place-items-center"></i>
                        </div>
                        <input
                            type="password" name="changepassword"
                            className='outline-none border rounded-sm p-2 text-[12px]'
                            placeholder='Enter new password...'
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            onClick={() => {
                                setErrorMsg(false);
                                setSuccessMsg(false);
                            }}
                        />
                        <span
                            style={{ "display": errorMsg ? "block" : "none" }}
                            className='text-[12px] text-red-500'
                        >*required</span>
                    </div>



                    <button
                        className='bg-blue-400 text-white rounded-sm p-1'
                        onClick={changePassFunc}
                    >
                        Submit</button>
                </div>
            </main>
        </>
    )
}

export default Changepassword
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import loginBg from '../assets/imgs/login_bg.jpg';
import loginImg from '../assets/imgs/login_image.png'
import {useNavigate } from 'react-router-dom';


const Login = () => {
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({ email: '', password: '' });
    const loginRegx = /^[a-zA-Z0-9 @#$_&.]{6,}$/;


    const checkAuth = async () => {
        const check = await fetch("/api/employee/check");
        const check_res = await check.json();
        console.log(check_res)
        if (check.status === 200 && check_res.login === true) {
            navigate("/")
        }
    }
    useEffect(() => {
        checkAuth()
    }, [])

    const getInputVal = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === 'user_name') {
            setLoginData((p_data) => {
                return {
                    email: value,
                    password: p_data.password
                }
            })
        } else if (name === 'pass') {
            setLoginData((p_data) => {
                return {
                    email: p_data.email,
                    password: value
                }
            })
        }

    }

    const Login = async (e) => {
        e.preventDefault();
        console.log(loginData)
        if ([loginData.email, loginData.password].every((field) => field !== "" ? true : false)) {
            if (loginRegx.test(loginData.username)) {

                const res = await fetch("/api/employee/login", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json"
                    },
                    body: JSON.stringify(loginData)
                });
                const login_res = await res.json();
                if (res.status === 200 && login_res.login === 'success') {
                    navigate('/');
                } else {
                    toast.error(login_res.error);
                }

            } else {
                toast.error('User name not support!');
            }
        } else {
            toast.error("fill the required", {
                duration: 2000,

            });
        }

        setLoginData({ password: "", email: "" });
    }


    return (
        <main className='login_main bg-[#F1F2F7] grid w-full min-h-screen place-items-center'>
            <Toaster />
            <div className="relative box w-full h-full lg:w-[60%] lg:h-[65%] flex flex-col lg:flex-row  justify-around items-center">
                <img src={loginBg} alt='"login_img' className='absolute w-full h-full z-0 lg:h-[100%] lg:w-full' />
                <div className="z-10 loginbox w-[90%] lg:w-[350px] h-[550px] lg:h-[450px] rounded-2xl bg-white ">
                    <form onSubmit={Login} className='login_from flex flex-col h-full justify-center items-center gap-5 w-full px-5'>
                        <h1 className='text-center  font-bold text-2xl text-[#3d3b3b]'>Login</h1>
                        <br />
                        <div className='relative w-full'>
                            <span className='absolute'>Email</span>
                            <input type="email" name="user_name" value={loginData.email} onChange={getInputVal} />
                        </div>

                        <div className='relative w-full'>
                            <span className='absolute'>Password</span>
                            <input type="password" name="pass" value={loginData.password} onChange={getInputVal} />
                        </div>

                        {/* <div className='w-full text-right'>
                            <a href="/" className='text-blue-700 text-sm'>
                                Forgot password?
                            </a>
                        </div> */}

                        <button type='submit' className='rounded-full px-8 py-[10px] bg-purple-500 text-white'>
                            Login
                        </button>
                    </form>
                </div>

                <div className='text-white flex flex-col items-center gap-3 z-10'>
                    {/* <h1 className='font-semibold text-lg'>Don't have an account?</h1>
                    <button className='register_btn border-white border-[3px] rounded-full px-7 py-2 text-sm'>
                        Register
                    </button> */}
                    <img src={loginImg} alt="login images" className='h-[350px] hidden sm:block' />
                </div>
            </div>
        </main>
    )
}

export default Login;


import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import './assets/css/style.css'
import Changepassword from './pages/Changepassword';
import AttendanceList from './pages/AttendanceList';

const App = () => {
  return (
    <>

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/changepassword' element={<Changepassword />} />
        <Route path='/attendance-list' element={<AttendanceList/>}/>

        <Route path='*' element={
          <div className='text-center min-h-[100vh] w-full grid place-items-center'>
            <div>
              <h1 className='font-mono'>404 Not Found</h1>
              <Link to={"/"} className='text-blue-600 text-sm hover:underline'>Home</Link>
            </div>
          </div>
        } />
      </Routes>

    </>
  )
}

export default App
import React, { useEffect, useRef, useState } from 'react';
import Nav from '../components/Nav';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const AttendanceList = () => {
  const [attendanceList, setAttendanceList] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [selectedDate, setSelectedDate] = useState("")
  const [employeeName, setEmployeeName] = useState([]);
  const userRef = useRef(null);
  const passRef = useRef(null);
  const credentials = { user: "tisindia", pass: "tis@123" };
  const [dialog, setDialog] = useState(true);
  const [workReport, setWorkReport] = useState('')
  // let workReportPopup = false;
  const [workReportPopup, setWorkReportPopup] = useState(false)

  const getAttendance = async () => {
    try {
      const get = await fetch("/api/attendance/get");
      const res = await get.json();
      
      if (get.status === 200 && res.data) {
        let todayDate = new Date().toLocaleDateString();
        let filterValue = res.data.filter((data, index) => data.inDate === todayDate);
        setAttendance([...filterValue]);
        setAttendanceList([...res.data]);
      }
    } catch (er) {
      console.log(er)
    }
  }

  useEffect(() => {
    console.log('run usereffect')
    getAttendance();
    setSelectedDate(new Date().toLocaleDateString())
  }, [])

  const getDate = (e) => {
    let date = new Date(e);
    setSelectedDate(e);
  }

  const changeDate = () => {
    let temp_a = [...attendanceList];
    let date = new Date(selectedDate).toLocaleDateString();
    if (selectedDate === 'Invalid Date') {
      setAttendance([...temp_a]);
      return;
    }
    temp_a = temp_a.filter((data, index) => data.inDate === date);
    setAttendance([...temp_a]);
  }


  const getEmployeeName = (e) => {
    let name = e.target.value;
    let temp = [...attendance];

    if (name) {
      temp = temp.filter((data, index) => data.name === name);
      setAttendance([...temp])
    } else {
      setAttendance([...temp]);
    }

  }

  const setUser = () => {
    let employeeName = [];

    attendanceList.forEach((data, index) => {
      if (!employeeName.includes(data.name)) {
        employeeName.push(data.name);
      }
    })

    setEmployeeName([...employeeName]);

  }
  useEffect(() => {
    setUser()
  }, [attendanceList])


  const loginAdmin = (e) => {
    e.preventDefault();
    const user = userRef.current.value;
    const pass = passRef.current.value;

    if (user === credentials.user && pass === credentials.pass) {
      setDialog(false)
    } else {
      alert("invalid credentials");
    }
  }

  const showReport = (report) => {
    // setWorkReport(report);
    document.querySelector("#reportArea").value = report
    setWorkReportPopup(true)
    console.log(report)
  }
  return (
    <>
      <Nav />

      {/* <div
        style={{ display: dialog ? "grid" : "none" }}
        className='overlay w-full min-h-full bg-white z-[99999] grid place-items-center'>
        <div className="login bg-blue-500 w-[350px] p-10 rounded-md">
          <h1 className='mb-5 text-white text-2xl font-bold text-left'>Login</h1>
          <form className='flex flex-col gap-4' onSubmit={loginAdmin}>
            <input type="text" name="username"
              className='outline-none p-2 rounded-3xl text-[12px]'
              placeholder='Username'
              ref={userRef}
            />
            <input type="password" name="password"
              className='outline-none p-2 rounded-3xl text-[12px]'
              placeholder='Password'
              ref={passRef}
            />

            <button
              className='bg-blue-600 text-white p-1 rounded-3xl'
            >Login</button>
          </form>
        </div>
      </div> */}

      <div className={`work_popup w-full min-h-[100vh] fixed bg-[#00000070] z-[99999] ${workReportPopup?"grid":'hidden'}  place-items-center`} onClick={()=>{
        setWorkReportPopup(false)
      }}>
        <textarea name="" className='outline-none border w-[70%] rounded-md text-black bg-white text-sm p-3' disabled id='reportArea'>{workReport}</textarea>
      </div>

      <main id='main' className='w-[calc(100%-170px)]  min-h-[100vh] float-right bg-[#F1F2F7] px-5'>
        <div className="attendance_top mt-14">
          <DatePicker
            selected={selectedDate}
            onChange={getDate}
            className='border rounded-md outline-none p-2'
          />
          <button
            onClick={changeDate}
            className='bg-blue-500 text-white px-5 rounded-md'>
            Search
          </button>
        </div>

        <div className='attendance_table overflow-auto'>
          <table className='w-full text-center'>
            <thead className='bg-gray-200'>
              <tr className='font-semibold'>
                <td align='left' className='pl-5 flex justify-between'>
                  <select
                    onChange={getEmployeeName}
                    name="employeeList"
                    className='w-[50%] bg-transparent border-none outline-none'>
                    <option value="" className='text-[12px]'>All</option>
                    {
                      employeeName.map((name, index) => (
                        <option value={`${name}`} key={index} className='text-[12px]'>{name}</option>
                      ))
                    }
                  </select>
                </td>
                <td>In Time</td>
                <td>Out Time</td>
                <td>In Date</td>
                <td>Work Report</td>
              </tr>
            </thead>
            <tbody className='text-sm'>

              {
                attendance.map((data, index) => (
                  <tr key={index}>
                    <td align='left' className='pl-5'>{data.name}</td>
                    <td>{data.inTime}</td>
                    <td>{data.outTime}</td>
                    <td>{data.inDate}</td>
                    <td>
                      <button
                        onClick={() => showReport(data.workReport)}
                        className='bg-blue-600 px-2 py-1 rounded-sm text-sm text-white'>
                        View
                      </button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

      </main>
    </>
  )
}

export default AttendanceList